export const userSetProfile = profile => ({
    type: 'USER_SET_PROFILE',
    profile
})

export const userSetLevel = userLevel => ({
    type: 'USER_SET_LEVEL',
    userLevel
})

export const userSetType = userType => ({
    type: 'USER_SET_TYPE',
    userType
})

export const userSetCanView = canView => ({
    type: 'USER_SET_CAN_VIEW',
    canView
})

export const userSetIsPremium = isPremium => ({
    type: 'USER_SET_IS_PREMIUM',
    isPremium
})

export const userSetPremiumExpire = premiumExpire => ({
    type: 'USER_SET_PREMIUM_EXPIRE',
    premiumExpire
})

export const userSetPremiumPlatForm = premiumPlatform => ({
    type: 'USER_SET_PREMIUM_PLATFORM',
    premiumPlatform
})

export const userSetValid = userValid => ({
    type: 'USER_SET_VALID',
    userValid
})

export const userClear = () => ({
    type: 'USER_CLEAR'
})

export const userSetName = userName => ({
    type: 'USER_SET_NAME',
    userName
})

export const userSetIsImpersonatedLogin = isImpersonatedLogin => ({
    type: 'USER_SET_IS_IMPERSONATEDLOGIN',
    isImpersonatedLogin
})



