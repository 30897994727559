import React, { useState, useEffect , useRef } from 'react';
import { Row, Col, InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from '../../components/navbar/Head.js';
import NavBar from '../../components/navbar/NavBar.js';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import Icon_DotRed from '../../image/dot_red.png';
import Icon_Public from '../../image/dot_green.png';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getPagesSelf , rowReorderPagesShelfDetails , deletePagesShelf , duplicatePagesShelf , ItemPagesShelf , reorderTableRowIndex} from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import { dialogConfirmSetData, dialogConfirmSetShowModal } from '../../actions/confirmDialog.action.js';
import { InputText } from 'primereact/inputtext';

const PagesShelf = () => {
    const navigate = useNavigate();
    const [pagesSelfDetails, setPagesSelfDetails] = useState([]);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState('');
    const cellEditCompletePending = useRef(false)

    useEffect(() => {
        showPagesSelf();
    }, []);

    const handleChange = (event) => {

        const { name, value } = event.target || '';
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        if (event.key === 'Enter') {
            handleSearch();
        }

    }

    const handleSearch = () => {
        const keyword = formData.keyword || '';
        const columnName = formData.columnName || 'tabName';
        ItemPagesShelf(keyword, columnName).then(response => {
            setPagesSelfDetails(response.data);
        });
    }




    function handleEditShelf(pagesTabId) {
        navigate('/ManagePageShelf', {
            state: {
                pagesTabId: pagesTabId
            },

        });
    }

    function handleDuplicateShelf(pagesShelfId){
        dispatch(dialogConfirmSetData({
            title: "Duplicate Page",
            message: " Are you certain you want to duplicate this page? A new page with the same contents will be created.",
            closeButton: "Cancel",
            confirmButton: "Confirm",
            eventConfirmButton: () => duplicatePagesShelf(pagesShelfId).then(response => {
                showPagesSelf();

            }),
            eventCloseButton: () => null,
            showCloseButton: true
        }));
        // ** ทำให้ dialog โชว์ขึ้นมา **
        dispatch(dialogConfirmSetShowModal(true));

    }

    function handleDeleteShelf(pagesShelfId) {
        dispatch(dialogConfirmSetData({
            title: "Confirm Page Deletion",
            message: " Are you sure you want to delete this page?",
            closeButton: "Cancel",
            confirmButton: "Confirm",
            eventConfirmButton: () => deletePagesShelf(pagesShelfId).then(response => {
                
                const newData = pagesSelfDetails.filter(item => item.tab_id !== pagesShelfId);
                const pagesShelfIds = newData.map(item => item.tab_id);
              
                if(pagesShelfIds.length > 0){
                    reorderItem(pagesShelfIds);
                }else{
                    setPagesSelfDetails(newData);
                }

            }),
            eventCloseButton: () => null,
            showCloseButton: true
        }));
        // ** ทำให้ dialog โชว์ขึ้นมา **
        dispatch(dialogConfirmSetShowModal(true));
    }




    const handleLinkClick = (url) => {
        navigate(url);
    }


    function showPagesSelf() {
        let pagesTabId = '';
        getPagesSelf(pagesTabId).then(response => {
            setPagesSelfDetails(response.data);
            console.log(response.data);

        });
    }



    function onRowReorder(event) {
        const pagesShelfIds = event.value.map(item => item.tab_id);
        reorderItem(pagesShelfIds);
    }


    function reorderItem(pagesShelfIds) {
        rowReorderPagesShelfDetails(pagesShelfIds).then(response => {
            setPagesSelfDetails(response.data);
        });
    }

    const handleRowClick = (rowData) => {
        navigate('/ShelfManagement', {
            state: {
                pagesTabId: rowData['tab_id'],
                tabName: rowData['tab_name']
            },

        });
       
    };

    
    const textEditorDisplayOrder = (options) => {
        return (
            <InputText
                type="number"
                value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
                //onBlur={(e) => options.editorCallback(e.target.value)} // Update on blur
                onBlur={(e) => {
                    if (e.target.value !== options.value) {
                        options.editorCallback(e.target.value);
                    }
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        options.editorCallback(e.target.value); // Update on Enter key press
                    }
                }}
                style={{ width: '100%' }} // Adjust width
            />
        );
    };


    const onCellEditCompleteDisplay = (event) => {
        let objectIdKey = 'tab_id';
        let normalizedArray =  reorderTableRowIndex(event,objectIdKey ,pagesSelfDetails );
        setPagesSelfDetails(normalizedArray);
        const reorderItemDisplay = normalizedArray.map(item => item[objectIdKey]);//[12, 11,32,43]
        reorderItem(reorderItemDisplay);
    }

    const columns = [
        {
           field: 'display_order', 
            header: 'No', 
            render: (rowData) => (
                <p className='td-text'>
                    {rowData.display_order}
                </p>
            ) , 
            editor: (options) => textEditorDisplayOrder(options),
            onCellEditCompleteDisplay: (event) => {
                // ป้องกันการเรียกซ้ำซ้อน
                if (cellEditCompletePending.current) {
                    return;
                }
            
                // ตั้งค่าเป็น true เพื่อป้องกันการทำงานซ้ำ
                cellEditCompletePending.current = true;
            
                // ตั้งค่า timeout เพื่อป้องกันการเรียกซ้ำจากหลาย event
                setTimeout(() => {
                    // Logic สำหรับการบันทึกผลการแก้ไขเซลล์ที่นี่
                  //  console.log('Cell edited:', event);
                  onCellEditCompleteDisplay(event);
                    // ตั้งค่ากลับเป็น false เมื่อเสร็จสิ้น
                    cellEditCompletePending.current = false;
                }, 0); // ใช้ timeout สั้นๆ เพื่อให้แน่ใจว่าฟังก์ชันทำงานเพียงครั้งเดียว
            }

        },
        {
            field: 'tab_name', header: 'Main Category', render: (rowData) => (
                <p className='td-text'>
                    {rowData.tab_name}
                </p>
            )

        },
        
        {
            field: 'tab_active', header: 'Status', render: (rowData) => (
                <p className='td-text'>
                    {rowData.tab_active === '1' && (
                        <React.Fragment>
                            <img src={Icon_Public} width='12px' className='str_icon' />
                            {'Active'}
                        </React.Fragment>
                    )}
                    {rowData.tab_active === '0' && (
                        <React.Fragment>
                            <img src={Icon_DotRed} width='12px' className='str_icon' />
                            {'Inactive'}
                        </React.Fragment>
                    )}
                </p>
            )
        },

        {
            field: 'Action', header: 'Action', render: (rowData) => (
                <div>
                    <a onClick={() => handleDuplicateShelf(rowData.tab_id   )}
                        className='normal-link link-button'>
                        Duplicate
                    </a>
                    {' | '}
                    <a onClick={() => handleEditShelf(rowData.tab_id)}
                        className='normal-link link-button'>
                        Edit
                    </a>
                    {rowData.tab_id !== '33' && (
                        <div className='button-delete-with-separator'>
                            {' | '}
                            <a onClick={() => handleDeleteShelf(rowData.tab_id)}
                                className='delete-link link-button'>
                                Delete
                            </a>
                        </div>
                    )}
                </div>
            )
        }
    ];
    const dynamicColumns = columns.map((col, i) => {
        return <Column reorderable={false} reorderableColumns={false} key={col.field} columnKey={col.field} field={col.field} header={col.header} 
        editor={col.editor}  onCellEditComplete={col.onCellEditCompleteDisplay} className={col.field === 'display_order' ? 'fixed-width-column' : ''}
        body={(rowData) => (
            <div onClick={() => col.field !== 'display_order' && col.field !== 'Action' && handleRowClick(rowData)}>
            {col.render(rowData)}
        </div>
        
        )}
        />;
    });



    return (
        <div >
            <Head />
            <Row className='row-navbar'>
                <NavBar />
                <Col className='row-context' >
                    <main>

                        <div className='box-context'>


                            <div className='div-row-col'>
                                <Row sm={12}>

                                    <span className='title-text'>Pages</span>
                                    <p className='context-text'>Pages description</p>

                                    <Col sm={12} md={5}>  <Button onClick={() => handleLinkClick('/ManagePageShelf')} className='button-action button-main button-orange' variant="primary" type="submit">+ New Pages</Button></Col>
                                    <Col sm={12} md={7}>
                                        <InputGroup>

                                            <Form.Control style={{ width: '150px' }} className='form-control-custom' name='keyword'
                                                onKeyUp={(e) => {
                                                    handleChange(e);

                                                }}
                                                placeholder="Search..." aria-label="Search..." />
                                            <Form.Select name='columnName' onChange={handleChange} className='form-control-custom2' style={{ width: '20px', borderBottomRightRadius: '5px', borderTopRightRadius: '5px', fontFamily: 'Sukhumvit Set' }} >
                                                <option value="tabName" >Main Category</option>
                                                <option value="status" >Status</option>
                                            </Form.Select>

                                            <div md={1} style={{ marginLeft: '10px' }}>

                                                <Button className='button-search button-main button-white' variant="primary" onClick={() => {
                                                    handleSearch();
                                                }} >Search</Button>


                                            </div>
                                        </InputGroup>
                                    </Col>
                                </Row>

                            </div>








                            <div className='box-table'>
                                <Card className='card-box'>
                                    <Card.Body>
                                        <Col className='' >
                                            <main>
                                                <div className=''>
                                                    <div className=''>
                                                        <DataTable value={pagesSelfDetails} reorderableColumns={false} reorderableRows onRowReorder={onRowReorder} selectionMode="single"  className="reorder-datatable" tableStyle={{ width: '100%' }} editMode="cell" >
                                                            <Column rowReorder className='reorder-datatable-column content-center' />
                                                            {dynamicColumns}
                                                        </DataTable>

                                                    </div>
                                                </div>

                                            </main>
                                        </Col>

                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                    </main>
                </Col>


            </Row>
        </div >
    );

}


export default PagesShelf;