import React, { useState, useEffect ,useRef } from 'react';
import { Row, Col, InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import '../../css/app.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from '../../components/navbar/Head.js';
import NavBar from '../../components/navbar/NavBar.js';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import { getBannerDetails, deleteBanner, rowReorderBannerDetails, searchBannerDetails ,reorderTableRowIndex } from '../../api'
import Icon_DotRed from '../../image/dot_red.png';
import Icon_Public from '../../image/dot_green.png';
import { useDispatch, useSelector } from 'react-redux';
import { dialogConfirmSetData, dialogConfirmSetShowModal } from '../../actions/confirmDialog.action.js';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Button from 'react-bootstrap/Button';
import { InputText } from 'primereact/inputtext';


const Banner = () => {
    const [bannerDetails, setBannerDetails] = useState([]);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState('');
    const navigate = useNavigate();

    const cellEditCompletePending = useRef(false)

    const handleLinkClick = (url) => {
        // Replace the current URL
        navigate(url);
    }

    function handleEditBanner(bannerId) {
        navigate('/AddBanner', {
            state: {
                bannerId: bannerId
            },

        });
    }

    function handleDeleteBanner(bannerId) {

        dispatch(dialogConfirmSetData({
            title: "Confirm Banner Deletion",
            message: " Are you sure you want to delete this banner?",
            closeButton: "Cancel",
            confirmButton: "Confirm",
            eventConfirmButton: () => deleteBanner(bannerId).then(response => {
                // [19,12,23,45,23]
                const newData = bannerDetails.filter(item => item.banner_id !== bannerId);
                //sort [19,23,45,23]
                const bannerIds = newData.map(item => item.banner_id); //[19,23,45,23]
                if(bannerIds.length > 0){
                    reorderItem(bannerIds);
                }else{
                    setBannerDetails(newData); // set empty datasource
                }

            }),
            eventCloseButton: () => null,
            showCloseButton: true
        }));
        // ** ทำให้ dialog โชว์ขึ้นมา **
        dispatch(dialogConfirmSetShowModal(true));

    }


    function showBannerDetails() {
        let bannerId = '';
        getBannerDetails(bannerId).then(response => {
            setBannerDetails(response.data);
           // console.log(response.data);

        });
    }

    function onRowReorder(event) {
        const bannerIds = event.value.map(item => item.banner_id);
        reorderItem(bannerIds);
    }

    useEffect(() => {
        showBannerDetails();
    }, []);

    function reorderItem(bannerIds) {
        rowReorderBannerDetails(bannerIds).then(response => {
            setBannerDetails(response.data);
            //console.log(response.data);

        });
    }



    const handleChangeSeach = (event) => {
        const { name, value } = event.target || '';
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        if (event.key === 'Enter') {
            // do whatever
            handleSearch();
        }
    }

    const handleSearch = () => {
        const keyword = formData.keyword || '';
        const columnName = formData.columnName || 'referenceName';
        searchBannerDetails(keyword, columnName).then(response => {
            //console.log(response.data);
            setBannerDetails(response.data);
        });
    }



    const onCellEditCompleteDisplay = (e) => {
        // console.log(e);
        let id = 'banner_id';
        let normalizedArray =  reorderTableRowIndex(e,id ,bannerDetails );
        setBannerDetails(normalizedArray);
        const reorderItemDisplay = normalizedArray.map(item => item[id]);//[12, 11,32,43]
        reorderItem(reorderItemDisplay);
    }
    
    
    
   

 
    const textEditorDisplayOrder = (options) => {
        return (
            <InputText
                type="number"
                value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
                //onBlur={(e) => options.editorCallback(e.target.value)} // Update on blur
                onBlur={(e) => {
                    if (e.target.value !== options.value) {
                        options.editorCallback(e.target.value);
                    }
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        options.editorCallback(e.target.value); // Update on Enter key press
                    }
                }}
                style={{ width: '100%' }} // Adjust width
            />
        );
    };





    const columns = [
        {
            field: 'display_order', 
            header: 'No', 
            render: (rowData) => (
                <p className='td-text'>
                    {rowData.display_order}
                </p>
            ) , 
            editor: (options) => textEditorDisplayOrder(options),
            onCellEditCompleteDisplay: (event) => {
                // ป้องกันการเรียกซ้ำซ้อน
                if (cellEditCompletePending.current) {
                    return;
                }
            
                // ตั้งค่าเป็น true เพื่อป้องกันการทำงานซ้ำ
                cellEditCompletePending.current = true;
            
                // ตั้งค่า timeout เพื่อป้องกันการเรียกซ้ำจากหลาย event
                setTimeout(() => {
                    // Logic สำหรับการบันทึกผลการแก้ไขเซลล์ที่นี่
                  //  console.log('Cell edited:', event);
                  onCellEditCompleteDisplay(event);
                    // ตั้งค่ากลับเป็น false เมื่อเสร็จสิ้น
                    cellEditCompletePending.current = false;
                }, 0); // ใช้ timeout สั้นๆ เพื่อให้แน่ใจว่าฟังก์ชันทำงานเพียงครั้งเดียว
            }

        },
        {
            field: 'reference_name', header: 'Reference Name', render: (rowData) => (
                <p className='td-text'>
                    {rowData.reference_name}
                </p>
            )

        },
        {
            field: 'banner_type', header: 'Link Type', render: (rowData) => (
                <div>
                    {rowData.banner_type === 'book' && (
                        <p className='td-text'>Book</p>
                    )}
                    {rowData.banner_type === 'custom' && (
                        <p className='td-text'>Custom URL</p>
                    )}
                </div>
            )
        },
        {
            field: 'image_url', header: 'Link To', render: (rowData) => (
                <div>
                    {rowData.banner_type === 'book' && (
                        <p className='td-text'>
                            {rowData.book_title}
                        </p>
                    )}
                    {rowData.banner_type === 'custom' && (
                        <p className='td-text'>
                            {rowData.banner_content}
                        </p>
                    )}
                </div>

            )
        },
        {
            field: 'active', header: 'Status', render: (rowData) => (
                <p className='td-text'>
                    {rowData.active === '1' && (
                        <React.Fragment>
                            <img src={Icon_Public} width='12px' className='str_icon' />
                            {'Active'}
                        </React.Fragment>
                    )}
                    {rowData.active === '0' && (
                        <React.Fragment>
                            <img src={Icon_DotRed} width='12px' className='str_icon' />
                            {'Inactive'}
                        </React.Fragment>
                    )}
                </p>
            )
        },

        {
            field: 'Action', header: 'Action', render: (rowData) => (
                <div>
                    <a  onClick={() => handleEditBanner(rowData.banner_id)}
                        className='normal-link link-button'>
                        Edit
                    </a>
                    {' | '}
                    <a onClick={() => handleDeleteBanner(rowData.banner_id)}
                        className='delete-link link-button'>
                        Delete
                    </a>
                </div>
            )
        }




    ];
    const dynamicColumns = columns.map((col, i) => {
        return <Column reorderable={false} reorderableColumns={false} key={col.field} columnKey={col.field} field={col.field} header={col.header} body={col.render}  editor={col.editor}  onCellEditComplete={col.onCellEditCompleteDisplay} className={col.field === 'display_order' ? 'fixed-width-column' : ''} />;
    });


    return (
        <div >
            <Head />
            <Row className='row-navbar'>
                <NavBar />
                <Col className='row-context' >
                    <main>

                        <div className='box-context'>
                            <div className='div-row-col'>
                                <Row sm={12}>

                                    <span className='title-text'>Banner</span>
                                    <p className='context-text'>Banners description.</p>

                                    <Col sm={12} md={5}>
                                        <Button onClick={() => handleLinkClick('/AddBanner')} className='button-action button-main button-orange' variant="primary" type="submit">+ Add Banner</Button>
                                    </Col>
                                    <Col sm={12} md={7}>
                                        <InputGroup>

                                            <Form.Control style={{ width: '150px' }} className='form-control-custom' name='keyword'
                                                onKeyUp={(e) => {
                                                    handleChangeSeach(e);

                                                }}
                                                placeholder="Search..." aria-label="Search..." />
                                            <Form.Select name='columnName' onChange={handleChangeSeach} className='form-control-custom2' style={{ width: '20px', borderBottomRightRadius: '5px', borderTopRightRadius: '5px', fontFamily: 'Sukhumvit Set' }} >

                                                <option value="referenceName" >Reference Name</option>
                                                <option value="linkType" >Link Type</option>
                                                <option value="linkTo" >Link To</option>
                                                <option value="status" >Status</option>

                                            </Form.Select>

                                            <div md={1} style={{ marginLeft: '10px' }}>

                                                <Button className='button-search button-main button-white' variant="primary" onClick={() => {
                                                            handleSearch();
                                                        }} >Search</Button>
                                            </div>
                                        </InputGroup>
                                    </Col>
                                </Row>

                            </div>


                            <div className='box-table'>
                                <Card className='card-box'>
                                    <Card.Body>

                                        <Col>
                                            <main>
                                                <div className="card-1">
                                                    <DataTable value={bannerDetails} reorderableRows onRowReorder={onRowReorder}  className="reorder-datatable" tableStyle={{ width: '100%' }} 
                                                    editMode="cell" >
                                                        <Column rowReorder className='reorder-datatable-column content-center' />
                                                        {dynamicColumns}
                                                    </DataTable>
                                    
                                                </div>
                                            </main>
                                        </Col>

                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                    </main>
                </Col>


            </Row>
        </div >
    );

}


export default Banner;