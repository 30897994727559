import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from '../../components/navbar/Head.js';
import NavBar from '../../components/navbar/NavBar.js';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { useState, useEffect } from 'react';
import 'react-tagsinput/react-tagsinput.css'
import { convertToNumberWith2Decimal, getCurrencyDisplay,localeString, getEbookItems, updateEbookApproveStatusDetails, STATUS_REJECTED, STATUS_READY_FOR_SALE, STATUS_PENDING_RELEASE, STATUS_IN_REVIEW ,STATUS_PUBLISHER_REMOVE_FROM_SALE,STATUS_WAITING_FOR_REVIEW, STATUS_PREPARE_FOR_SUBMISSION,STATUS_REMOVED_FROM_SALE, STATUS_PUBLISHER_REJECTED} from '../../api'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import accessPath from '../../api/index.js';
import { hideLoading, isUserLogedIn, showLoading } from '../../helper/Helper.js';
import { dialogConfirmSetData, dialogConfirmSetShowModal } from '../../actions/confirmDialog.action.js';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import ImageWithPlaceholder from '../../components/ImageWithPlaceholder/index.js';
import placeholderImg from '../../image/placeHolder_cover.png';     
import EbookStatus from '../../components/EbookStatus/index.js';


const ViewEbooks = () => {

    const location = useLocation();
    const { ebookDetailsID, bookTitle , searchKeyword , searchOption } = location.state || {};
    const [ebookId, setEbookId] = useState(ebookDetailsID);
    const [uploadEBook, setUploadEBook] = useState(null);
    const [selectedPdf, setSelectedPdf] = useState(null);
    const [selectedOnlyOver, setOnlyOver] = useState('');
    const [PricingAndSales, setPricingAndSales] = useState('');
    const [publishOption, setPublishOption] = useState('as_review_pass');
    const [datagetEbookDetails, setgetEbookDetails] = useState([]);
    const [tagsDetails, setTagsDetails] = useState([]);
    const [keywordsDetails, setKeywordsDetails] = useState([]);
    const dispatch = useDispatch();

    const handleBackClick = () => {
        navigate('/ManageBooks', { 
            replace: true ,
            state: {
                searchKeyword: searchKeyword || '',
                searchOption: searchOption || ''
            }
        });
    };

    function showEbookDetails() {
        getEbookItems(ebookDetailsID).then(handleShowEbookDetails)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        setPublishOption('as_review_pass');
        setPricingAndSales('for_sale');
        showEbookDetails();
        setOnlyOver('0');
    }, []);

    const handleShowEbookDetails = async (response) => {
        // console.log(response.data);
        if (response.data[0] === undefined) {
            navigate('/ManageBooks', { replace: true });
        } else {
           
            setgetEbookDetails(response.data[0]);

            if (response.data[0]['for_age_18_plus'] == '1') {
                setOnlyOver('1');
            }

            if (response.data[0]['sale_type'] == 'for_sale') {
                setPricingAndSales('for_sale');
            } else {
                setPricingAndSales('free');
            }

            setPublishOption(response.data[0]['publish_options']);    

            if (response.data[0]['tags'] != null) {
                const tagsArray = response.data[0]['tags'].split(',');
                setTagsDetails(tagsArray);
            }

            if (response.data[0]['keywords'] != null) {
                const KeywordsArray = response.data[0]['keywords'].split(',');
                setKeywordsDetails(KeywordsArray);
            }

            let urlProfile = accessPath + response.data[0]['cover_image_path'];
            setUploadEBook(urlProfile);

            let urlPdf = accessPath + response.data[0]['uploaded_pdf_path'];
            let uploadedPdfPath = response.data[0]['uploaded_pdf_path'];
          
            if (uploadedPdfPath != null ) {
                setSelectedPdf(urlPdf);
            }
           
        }

    }

    async function updateEbookStatus(approveStatusId) {
        showLoading();
        await updateEbookApproveStatusDetails(ebookId, approveStatusId).then(response => {
            hideLoading();
            navigate('/ManageBooks',{ replace: true });
        });
    }

    const updateEbookApproveStatus = () => {

        dispatch(dialogConfirmSetData({
            title: "Please confirm submission for approval.",
            message: "Are you sure you want to submit it for approval? Your E-Books will be queued for review.",
            closeButton: "Cancel",
            confirmButton: "Confirm",
            eventConfirmButton: () => updateEbookStatus(STATUS_WAITING_FOR_REVIEW),
            eventCloseButton: () => null,
            showCloseButton: true
        }));
        // ** ทำให้ dialog โชว์ขึ้นมา **
        dispatch(dialogConfirmSetShowModal(true));
    };

    const updateEbookDesignerRejected = () => {

        dispatch(dialogConfirmSetData({
            title: "Please Confirm Your Rejection",
            message: "Are you sure you want to reject your E-book from the review process? The book will be removed from the review queue.",
            closeButton: "Cancel",
            confirmButton: "Confirm",
            eventConfirmButton: () => updateEbookStatus(STATUS_PUBLISHER_REJECTED),
            eventCloseButton: () => null,
            showCloseButton: true
        }));
        // ** ทำให้ dialog โชว์ขึ้นมา **
        dispatch(dialogConfirmSetShowModal(true));
    };

    const updateEbookPublisherRemovedfromSale = () => {

        dispatch(dialogConfirmSetData({
            title: "Please confirm your own remove E-book from the store",
            message: "Are you sure you want to remove your own E-book from the store?",
            closeButton: "Cancel",
            confirmButton: "Confirm",
            eventConfirmButton: () => updateEbookStatus(STATUS_PUBLISHER_REMOVE_FROM_SALE),
            eventCloseButton: () => null,
            showCloseButton: true
        }));
        // ** ทำให้ dialog โชว์ขึ้นมา **
        dispatch(dialogConfirmSetShowModal(true));
    };

    const navigate = useNavigate();
    const handleEditEbooks = (ebookDetailsID) => {
        navigate('/EditEbooks', {
            state: {
                ebookDetailsID: ebookDetailsID
            },
            replace: true 
        });
    };

    return (
        <div >
            <Head />
            <Row className='row-navbar'>
                <NavBar />
                <Col className='row-context' >
                    <main>
                        <div className='box-context'>
                            <span className='title-text'>  <div className='title-content-text' onClick={() => handleBackClick()}>Manage Books</div><div style={{marginLeft: '10px',marginRight: '10px'}}>{'›'}</div>{datagetEbookDetails.book_title}   </span>
                            {(datagetEbookDetails.approve_status_id != STATUS_READY_FOR_SALE && datagetEbookDetails.approve_status_id != STATUS_PENDING_RELEASE && datagetEbookDetails.approve_status_id != STATUS_IN_REVIEW) ?
                                <p className='context-text'> Showing your book details , you can edit by pressing 'Edit' button below</p>
                                :
                                <p className='context-text'> Showing your book details.</p>
                            }

                            <Card className='card-box-status card-box-status-top-pading card-status'>
                            <Card.Body>
                                <Row>
                                                
                                    <div className='col-md-6 d-flex align-items-center'>
                                        <span style={{marginRight:'10px'}} className='inner-title-text'>Status:</span> <span className='inner-title-detail'><EbookStatus status={datagetEbookDetails.approve_status_id} iconSize={17} />{datagetEbookDetails.approve_status_name}</span>
                                    </div>
                                    <div className="col-md-6 d-flex justify-content-end align-items-end align-items-center">
                                        {datagetEbookDetails.cover_image_path != null && datagetEbookDetails.cover_image_path != '' && datagetEbookDetails.uploaded_pdf_path != null && datagetEbookDetails.uploaded_pdf_path != '' && (

                                            <div>
                                                {datagetEbookDetails.approve_status_id == STATUS_PREPARE_FOR_SUBMISSION && (
                                                    <div>
                                                        <Button className='button-action button-main button-orange' variant="primary" onClick={updateEbookApproveStatus}>Send for Approval</Button>
                                                    </div>
                                                )}

                                                {datagetEbookDetails.approve_status_id == STATUS_WAITING_FOR_REVIEW && (
                                                    <div>
                                                        <Button className='button-action button-main button-orange' variant="primary" onClick={updateEbookDesignerRejected}>Publisher Rejected</Button>
                                                    </div>
                                                )}

                                                {datagetEbookDetails.approve_status_id == STATUS_READY_FOR_SALE && (
                                                    <div>
                                                        <Button className='button-action button-main button-orange' variant="primary" onClick={updateEbookPublisherRemovedfromSale}>Publisher Removed from Sale</Button>
                                                    </div>
                                                )}
                                                {datagetEbookDetails.approve_status_id == STATUS_PENDING_RELEASE && (
                                                    <div>
                                                        <Button className='button-action button-main button-orange' variant="primary" onClick={updateEbookPublisherRemovedfromSale}>Publisher Removed from Sale</Button>
                                                    </div>
                                                )}
                                            </div>

                                        )}

                                    </div>
                                </Row>
                                <Row>
                                    {datagetEbookDetails.approve_notes != null && datagetEbookDetails.approve_notes != "" && (datagetEbookDetails.approve_status_id == STATUS_REJECTED || datagetEbookDetails.approve_status_id == STATUS_REMOVED_FROM_SALE) ?
                                        
                                        <div className='col-md-8 d-flex align-items-center' style={{paddingTop:'10px'}}>
                                            <span style={{marginRight:'10px'}} className='inner-title-text'>Remark:</span> <span className='inner-title-detail'>{datagetEbookDetails.approve_notes}</span>
                                        </div>
                                        : 
                                        <div />
                                    }
                                </Row>
                            </Card.Body>
                            </Card>

                            <Card className='card-box card-box-top-pading'>
                                <Card.Body>

                                    <div className='content-box'>
                                        <Row>
                                            <div className='col-md-12' >
                                            
                                                <Row className='align-items-end'>
                                                
                                                    <div className='col-md-6'>
                                                        <span className='inner-title-text'>Book Details</span>
                                                    </div>
                                                    
                                                </Row>
                                            </div>
                                        </Row>

                                        <hr className='hr-title' />
                                        <Form>

                                            <Form.Group as={Row} className="mb-12" controlId="">
                                                <Form.Label column sm="4">
                                                    <div style={{ paddingBottom: '270px' }}>
                                                        <span className='my-rowlabel'>  Upload E-Book Cover </span>
                                                    </div>
                                                </Form.Label>
                                                <Col sm="6" style={{ paddingTop: '30px' }}>
                                                    {uploadEBook && (
                                                        <div className="image-frame" >
                                                            <ImageWithPlaceholder
                                                                src={uploadEBook}
                                                                placeholder={placeholderImg}
                                                                className = 'book-overview'
                                                            />
                                                        </div>
                                                    )}
                                                </Col>
                                            </Form.Group>

                                            <br></br>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Book Title
                                                </Form.Label>
                                                <Col sm="6">
                                                    {(datagetEbookDetails.book_title != null && datagetEbookDetails.book_title !== '') ?
                                                        <Form.Label type="text" >{datagetEbookDetails.book_title} </Form.Label>
                                                        :
                                                        <Form.Label type="text" > - </Form.Label>
                                                    }
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Author Name
                                                </Form.Label>
                                                <Col sm="6">
                                                    {(datagetEbookDetails.book_author != null && datagetEbookDetails.book_author !== '') ?
                                                        <Form.Label type="text" >{datagetEbookDetails.book_author} </Form.Label>
                                                        :
                                                        <Form.Label type="text" > - </Form.Label>
                                                    }
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Category
                                                </Form.Label>
                                                <Col sm="6">
                                                    {(datagetEbookDetails.category_name != null && datagetEbookDetails.category_name !== '') ?
                                                        <Form.Label type="text" >{datagetEbookDetails.category_name} </Form.Label>
                                                        :
                                                        <Form.Label type="text" > - </Form.Label>
                                                    }
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">Book Series</Form.Label>
                                                <Col sm="6">
                                                    {(datagetEbookDetails.serie_name != null && datagetEbookDetails.serie_name != "") ? 
                                                        <Form.Label>{datagetEbookDetails.serie_name}</Form.Label>
                                                        :
                                                        <Form.Label type="text" > - </Form.Label>
                                                    }
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Label type="text" >{datagetEbookDetails.volume} </Form.Label>
                                                    <span className='text-alerts' >Order of books in this series</span>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Only for People Over 18 Years Old
                                                </Form.Label>
                                                <Col sm="6">
                                                    {selectedOnlyOver === '0' && (
                                                        <Form.Label >
                                                            No
                                                        </Form.Label>
                                                    )}

                                                    {selectedOnlyOver === '1' && (
                                                        <Form.Label >
                                                            Yes
                                                        </Form.Label>
                                                    )}

                                                </Col>
                                            </Form.Group>



                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Synopsis
                                                </Form.Label>
                                                <Col sm="6">
                                                    {(datagetEbookDetails.book_description != null && datagetEbookDetails.book_description !== '') ?
                                                        <Form.Label type="text" >{datagetEbookDetails.book_description} </Form.Label>
                                                        :
                                                        <Form.Label type="text" > - </Form.Label>
                                                    }
                                                </Col>
                                            </Form.Group>


                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Book Type
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Label type="text" >
                                                        {
                                                            datagetEbookDetails.book_type === '1' ?
                                                                'Thai Book' :
                                                                'Translated Book'
                                                        }
                                                    </Form.Label>

                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    ISBN
                                                </Form.Label>
                                                <Col sm="6">
                                                    {(datagetEbookDetails.isbn_number != null && datagetEbookDetails.isbn_number !== '') ?
                                                        <Form.Label type="text" >{datagetEbookDetails.isbn_number} </Form.Label>
                                                        :
                                                        <Form.Label type="text" > - </Form.Label>
                                                    }

                                                </Col>
                                            </Form.Group>



                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    ISBN E-Book
                                                </Form.Label>
                                                <Col sm="6">
                                                    {(datagetEbookDetails['isbn_ebook_number'] != null && datagetEbookDetails['isbn_ebook_number'] !== '') ?
                                                        <Form.Label type="text" >{datagetEbookDetails['isbn_ebook_number']} </Form.Label>
                                                        :
                                                        <Form.Label type="text" > - </Form.Label>
                                                    }

                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                Right-to-left reading style
                                                </Form.Label>
                                                <Col sm="6">
                                                    {(datagetEbookDetails['reading_mode'] == 'ltr') ?
                                                        <Form.Label type="text" >No</Form.Label>
                                                        :
                                                        <Form.Label type="text" >Yes</Form.Label>
                                                    }

                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                Reading Direction
                                                </Form.Label>
                                                <Col sm="6">
                                                    {(datagetEbookDetails['reading_orientation'] == 'vertical') ?
                                                        <Form.Label type="text" >Vertical Scrolling only</Form.Label>
                                                        :
                                                        <Form.Label type="text" >Horizontal and Vertical Scrolling</Form.Label>
                                                    }
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Tags
                                                </Form.Label>
                                                <Col sm="6">
                                                    {(tagsDetails.length >= 1) ?
                                                        <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }} className='div-my-buttonTags'>
                                                            {tagsDetails.map((option) => (
                                                                (option && option !== "") ? (
                                                                    <a key={option} style={{ marginRight: '2px' }} className="my-LabelKeywords">
                                                                        <span className='font-tags'>{option}</span>
                                                                    </a>
                                                                ) : null
                                                            ))}

                                                        </div>
                                                        :
                                                        <Form.Label type="text" > - </Form.Label>
                                                    }
                                                </Col>


                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Keywords
                                                </Form.Label>
                                                <Col sm="6">
                                                    {(keywordsDetails.length >= 1) ?
                                                        <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }} className='div-my-button'>
                                                            {keywordsDetails.map((option) => (
                                                                (option && option !== "") ? (
                                                                    <a key={option} style={{ marginRight: '2px' }} className="my-LabelKeywords">
                                                                        <span className='font-tags'>{option}</span>
                                                                    </a>
                                                                ) : null
                                                            ))}

                                                        </div>
                                                        :
                                                        <Form.Label type="text" > - </Form.Label>
                                                    }
                                                </Col>
                                            </Form.Group>


                                            <br></br>
                                            <span className='inner-title-text'>Pricing and Sales</span>
                                            <hr className='hr-title' />
                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Type Sales
                                                </Form.Label>
                                                <Col sm="6">

                                                    {PricingAndSales === 'for_sale' && (
                                                        <Form.Label >
                                                            Books for Sale
                                                        </Form.Label>
                                                    )}

                                                    {PricingAndSales === 'free' && (
                                                        <Form.Label >
                                                            Free Books
                                                        </Form.Label>
                                                    )}

                                                </Col>
                                            </Form.Group>

                                            {PricingAndSales === 'for_sale' && (
                                                <div>
                                                    <Form.Group as={Row} className="mb-3" controlId="">
                                                        <Form.Label column sm="4">
                                                            Paper Cover Price
                                                        </Form.Label>
                                                        <Col sm="6">
                                                            {(datagetEbookDetails['cover_price'] != null && datagetEbookDetails['cover_price'] !== '') ?
                                                                <Form.Label type="text" >{getCurrencyDisplay(convertToNumberWith2Decimal(datagetEbookDetails['cover_price']))}</Form.Label>
                                                                :
                                                                <Form.Label type="text" > - </Form.Label>
                                                            }
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row} className="mb-3" controlId="">
                                                        <Form.Label column sm="4">
                                                            Book Price
                                                        </Form.Label>
                                                        <Col sm="6">

                                                            {(datagetEbookDetails['price'] != null && datagetEbookDetails['price'] !== '') ?
                                                                <Form.Label type="text" >{getCurrencyDisplay(convertToNumberWith2Decimal(datagetEbookDetails['price']))}</Form.Label>
                                                                :
                                                                <Form.Label type="text" > - </Form.Label>
                                                            }
                                                        </Col>
                                                    </Form.Group>
                                                </div>
                                            )}

                                            <br></br>
                                            <span className='inner-title-text'>E-Book File</span>
                                            <hr className='hr-title' />

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Upload E-Book File
                                                </Form.Label>
                                                <Col sm="6">
                                                <div>
                                                    {selectedPdf ? (
                                                        <div>
                                                        <Form.Label column sm="4">
                                                            There is a file uploaded
                                                        </Form.Label>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                        <Form.Label column sm="4">
                                                            There is no file uploaded
                                                        </Form.Label>
                                                        </div>
                                                    )}
                                                </div>

                                                </Col>
                                            </Form.Group>

                                            <br></br>
                                            <span className='inner-title-text'>On-sale date</span>
                                            <hr className='hr-title' />
                                            <Form.Group as={Row} className="mb-12" controlId="">
                                                <Form.Label column sm="4">
                                                    <span className='my-rowlabel'>  Status</span>
                                                </Form.Label>
                                                <Col >

                                                    {publishOption === 'as_review_pass' && (
                                                        <Form.Label >
                                                            As soon as review pass
                                                        </Form.Label>
                                                    )}

                                                    {publishOption === 'schedule' && (
                                                        <Form.Label >
                                                            Scheduled
                                                        </Form.Label>
                                                    )}
                                                </Col>
                                            </Form.Group>

                                            {publishOption === 'schedule' && (
                                                <div>

                                                    <Form.Group as={Row} className="mb-3" controlId="">
                                                        <Form.Label column sm="4">
                                                        Publish Date
                                                        </Form.Label>
                                                        <Col sm="6">
                                                            <Form.Label type="text" >{localeString(datagetEbookDetails['publish_date'])} </Form.Label>
                                                        </Col>
                                                    </Form.Group>

                                                </div>
                                            )}

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                Note to Reviewers
                                                </Form.Label>
                                                <Col sm="6">
                                                    {(datagetEbookDetails['notes'] != null && datagetEbookDetails['notes'] !== '') ?
                                                        <Form.Label type="text" >{datagetEbookDetails['notes']} </Form.Label>
                                                        :
                                                        <Form.Label type="text" > - </Form.Label>
                                                    }
                                                </Col>
                                            </Form.Group>

                                            <br></br>

                                            <center>
                                                <br></br>
                                                {datagetEbookDetails.approve_status_id != STATUS_READY_FOR_SALE && datagetEbookDetails.approve_status_id != STATUS_PENDING_RELEASE && datagetEbookDetails.approve_status_id != STATUS_IN_REVIEW && (
                                                    <Button className='button-form-save button-main button-orange' variant="primary" onClick={() => handleEditEbooks(datagetEbookDetails['ebook_id'])} >Edit</Button>
                                                )}
                                            </center>
                                        </Form>
                                    </div>



                                </Card.Body>
                            </Card>
                        </div>


                    </main>
                </Col>


            </Row>
        </div >
    );
};

export default ViewEbooks;
