import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, InputGroup } from 'react-bootstrap';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from '../../components/navbar/Head.js';
import StatusEbooks from '../../components/StatusEbooks/index.js';
import NavBar from '../../components/navbar/NavBar.js';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect , useRef } from 'react';
import { capitalizeFirstLetter , checkImportEbooks, importEbooks, convertToStringWith2Decimal, localeString, limitPageEbookDetails, getEbookDetails, apiSearchEbookDetails, listEbookSearchOptions, STATUS_READY_FOR_SALE, STATUS_IN_REVIEW, STATUS_PENDING_RELEASE } from '../../api/index.js'
import { useNavigate , useLocation } from 'react-router-dom';
import accessPath from '../../api/index.js';
import { Alert } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import ListGroup from 'react-bootstrap/ListGroup';
import ImageWithPlaceholder from '../../components/ImageWithPlaceholder/index.js';
import placeholderImg from '../../image/placeHolder_cover.png'; 
import {  setCurrentManageBookPage , setManageSortOption} from '../../actions/site.action.js'; 
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hideLoading, showLoading } from '../../helper/Helper.js';

const ManageBooks = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const currentManageBookPage = useSelector(state => state.site.currentManageBookPage) || [];
    const manageBookSortOption = useSelector(state => state.site.manageBookSortOption);
    // console.log(manageBookSortOption);
   

    const currentScrollY = useSelector(state => state.site.currentScrollY);
    const userLevel = useSelector(state => state.user.userLevel);
    const [datagetEbookDetails, setEbookDetails] = useState([]);
    const [countEbookDetails, setCountEbookDetails] = useState([]);
    const [page, setPage] = useState(currentManageBookPage);
    const [formData, setFormData] = useState('');
    const [selectedFileTsv, setSelectedFileTsv] = useState(null);
    const [importEbookOpen, setImportEbookOpen] = useState(false);
    const handleImportEbookShow = () => setImportEbookOpen(true);
    const [importedData, setImportedData] = useState(null);
    const [isError, setIsError] = useState(false);
    const [errorDataEbook, setErrorDataEbook] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [errorCount, setErrorCount] = useState(0);
    const [nonErrorCount, setNonErrorCount] = useState(0);
    const [showDataImport, setShowDataImport] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [messageStatus , setMessageStatus] = useState('');
    const [showAlertCheckFile, setShowAlertCheckFile] = useState(false);
    const [checkImport , setCheckImport] = useState(false);
    const [messageImportSuccess , setMessageImportSuccess] = useState('');
    const [sortColumn, setSortColumn] = useState(manageBookSortOption);

    const getIcon = (columnKey) => {
      const sortItem = sortColumn.find(item => item.columnKey === columnKey);
      if (sortItem?.direction === 'ascending') {
        return <FontAwesomeIcon icon={faSortUp} />;
      }
      if (sortItem?.direction === 'descending') {
        return <FontAwesomeIcon icon={faSortDown} />;
      }
      return <FontAwesomeIcon icon={faSort} />;
    };
  
    const requestSort = (columnKey) => {
      setPage(1);
      let newSortConfig = [...sortColumn];
      const existingSortItemIndex = newSortConfig.findIndex(item => item.columnKey === columnKey);
  
      if (existingSortItemIndex !== -1) {
        if (newSortConfig[existingSortItemIndex].direction === 'ascending') {
          newSortConfig[existingSortItemIndex].direction = 'descending';
        } else if (newSortConfig[existingSortItemIndex].direction === 'descending') {
          newSortConfig.splice(existingSortItemIndex, 1);  // Remove the sort item if it's already descending
        }
      } else {
        newSortConfig.push({ columnKey: columnKey, direction: 'ascending' });
      }
    //   console.log(newSortConfig);
  
      setSortColumn(newSortConfig);
      showEbookDetails(1 ,newSortConfig );
      dispatch(setManageSortOption(newSortConfig));
    
    };
  
    const { searchKeyword , searchOption } = location.state || {};
    const [keyword, setKeyword] = useState(searchKeyword || '');
    const refKeywordEbook = useRef();

    useEffect(() => {    
        if (refKeywordEbook.current) {
          setKeyword(refKeywordEbook.current.value || '');
        }
    }, [refKeywordEbook , keyword]);

    const handleImportEbookClose = () => 
        {
            setShowSpinner(false);
            setImportEbookOpen(false);
            setIsError(false);
            setShowDataImport(false);
            setSelectedFileTsv(null);
            setShowAlertCheckFile(false);
            const newOption = 'title';
            navigate(location.pathname, { state: { ...location.state, searchOption: newOption , searchKeyword: '' } });

        }
    const [fileExtensionEbook , setFileExtensionEbook] = useState('');
    const handleFileChange = (event) => {
        setShowDataImport(false);
        setShowAlertCheckFile(false);
        if (event.target.files && event.target.files.length > 0) {
            const fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
            setFileExtensionEbook(fileExtension);
            if (fileExtension === 'tsv') {
                setSelectedFileTsv(event.target.files[0]);
                setShowAlertCheckFile(true);
            }
        }

        setShowAlertCheckFile(false);
    };

    const handleCheckImportTsv = () => {

    
        if (!selectedFileTsv || fileExtensionEbook != 'tsv') {
            setShowAlertCheckFile(true);
        }else{
            setShowSpinner(true);
            setShowDataImport(false);
            checkImportEbooks(selectedFileTsv).then(response => {
                setErrorDataEbook(response.data['showError'])
                setImportedData(response.data['matchedColumns']);
                setTotalData(response.data['totalData']);
                setErrorCount(response.data['errorCount']);
                setNonErrorCount(response.data['nonErrorCount']);
                setMessageStatus('validated');
                setMessageImportSuccess('Non-errors');
                if (response.data['isError']) {
                    setIsError(true);
                } else {

                   setIsError(false);
                }
                setShowDataImport(true);
                setShowSpinner(false)
            });
         }

    };

    const handleImportTsv = () => {

        if (!selectedFileTsv || fileExtensionEbook != 'tsv') {
            setShowAlertCheckFile(true);
        }else{
            setShowSpinner(true)
            setShowDataImport(false);
            importEbooks(selectedFileTsv).then(response => {
                
                setErrorDataEbook(response.data['showError'])
                setImportedData(response.data['matchedColumns']);
                setTotalData(response.data['totalData']);
                setErrorCount(response.data['errorCount']);
                setNonErrorCount(response.data['nonErrorCount']);
                setMessageStatus('imported');
              
                if (response.data['isError']) {
                    setIsError(true);
                } else {
                    setMessageImportSuccess('Success');
                    setCheckImport(true);
                    setIsError(false);
                    dispatch(setCurrentManageBookPage(1)); 
                }
                setShowDataImport(true);
                setShowSpinner(false)
                showEbookDetails(1);
                setSortColumn([]);
        
            });
        }

    };




    function showEbookDetails(page = 1 , newSortConfig) {
        const searchOptionEbook =  formData.searchOption || searchOption || listEbookSearchOptions[0]['value'];

        if (keyword != "") {
            // console.log(newSortConfig);
            searchEbookDetails(keyword, searchOptionEbook, page , newSortConfig);
          
        } else {
            showLoading();
            getEbookDetails(page, limitPageEbookDetails , newSortConfig ).then(response => {
                setEbookDetails(response.data['books']);
                let responseCount = "0";
                // ตรวจสอบว่า response.data['all_books_count'] ไม่เป็น undefined ก่อนที่จะเข้าถึงข้อมูล
                if (response.data['all_books_count'] && response.data['all_books_count'].length > 0) {
                    // หากข้อมูลมีอยู่จริง และมีอย่างน้อยหนึ่งสมาชิกในอาร์เรย์
                    responseCount = response.data['all_books_count'][0]['all_books'];
                } else {
                    // หากไม่มีข้อมูล หรืออาร์เรย์ว่างเปล่า
                    responseCount = 0; // กำหนดค่าเป็น 0 หรือค่าที่เหมาะสมตามที่คุณต้องการ
                }
                setCountEbookDetails(responseCount);
                hideLoading();  
                //currentScrollYEbook(); //ฟังค์เลื่อนไปตำแหน่งที่บันทึกเอาไว้
            });
        }
    }




    function searchEbookDetails(keyword, option, page = '1' , newSortConfig ) {
        showLoading();
        apiSearchEbookDetails(keyword, option, page, limitPageEbookDetails , newSortConfig ).then(response => {
            setEbookDetails(response.data['books']);
            let responseCount = "0";
            responseCount = response.data['all_books_count'][0]['all_books'];
            if (keyword !== '') {
                setCountEbookDetails(responseCount);
            } else {
                setCountEbookDetails(responseCount);
            }
            hideLoading();  
        });
    }

    const navigate = useNavigate();
    const handleEditEbooks = (ebookDetailsID) => {
        const getSearchOption = getOption();
        navigate('/EditEbooks', {
            state: {
                ebookDetailsID: ebookDetailsID ,
                searchKeyword : keyword ,
                searchOption : getSearchOption
            },
        });
    };

    const handleViewEbooks = (ebookDetailsID, bookTitle) => {
       const getSearchOption = getOption();
        navigate('/ViewEbooks', {
            state: {
                ebookDetailsID: ebookDetailsID,
                bookTitle: bookTitle ,
                searchKeyword : keyword ,
                searchOption : getSearchOption
            },
        });
    };

    const handleChange = (event) => {
        const { name, value } = event.target || '';
        setFormData((prevData) => ({ ...prevData, [name]: value }));

        if (event.key === 'Enter') {
            // do whatever
            handleSearch();
            setPage('1');
            setSortColumn([]);
        }

    }
    const handleSearch = () => {
        setSortColumn([]);
        const searchKeyword = formData.keyword !== undefined ? formData.keyword : keyword;
        const getSearchOption = getOption();
        setKeyword(searchKeyword);
        searchEbookDetails(searchKeyword, getSearchOption);
    }

    function getOption(){
        const getSearchOption = formData.searchOption || searchOption || listEbookSearchOptions[0]['value'];
        return getSearchOption;
    }


    useEffect(() => {
        window.scrollTo(0, currentScrollY);
        showEbookDetails(currentManageBookPage , sortColumn);
      
    }, []);

    const handleLinkClick = (url) => {
        navigate(url);
    };
    

    function currentScrollYEbook(){
        // window.scrollTo(0, currentScrollY); //เลื่อนไปตำแหน่่งเดิม
        // dispatch(setCurrentScrollY(0));//รีค่า
    }

    return (
        (userLevel !== '' && userLevel === 'guest') ?
            <div >
                <Head />
                <Row className='row-navbar'>
                    <NavBar />
                    <Col className='row-context' >
                        <main style={{ width: 'auto' }}>
                            <div className='box-context'>
                                <span className='title-text'>Manage Books</span>
                                <font className='context-text'>Manage all books you want.</font>

                                <div className='div-row-col'>
                                    <Row sm={12}>
                                        <Col sm={12} md={5}>
                                            <Button onClick={() => handleLinkClick('/AddBooks')} className='button-action button-main button-orange' variant="primary" type="submit">+ Add E-Book</Button>
                                            { }
                                            {/* <Button
                                                onClick={handleImportEbookShow}
                                                className='button-action button-main button-orange'
                                                style={{marginLeft: '6px'}}
                                                variant="primary"
                                                type="submit"
                                            >
                                                Bulk Import
                                            </Button> */}
                                        </Col>
                                        <Col sm={12} md={7}>
                                            <InputGroup>
                                                <Form.Control style={{ width: '150px' }} ref={refKeywordEbook} defaultValue={keyword} className='form-control-custom' name='keyword'
                                                    onKeyUp={(e) => {
                                                        handleChange(e);
                                                        //setPage('1');
                                                    }}

                                                    placeholder="Search..." aria-label="Search..." />
                                                <Form.Select name='searchOption' onChange={handleChange} defaultValue={searchOption} className='form-control-custom2' style={{ width: '20px', borderBottomRightRadius: '5px', borderTopRightRadius: '5px' }} >
                                                    {Object.keys(listEbookSearchOptions).map((key) => (
                                                        <option value={listEbookSearchOptions[key].value} key={key}>
                                                            {listEbookSearchOptions[key].name}
                                                        </option>
                                                    ))}

                                                </Form.Select>
                                                <div md={1} style={{ marginLeft: '10px' }}>

                                                    <Button className='button-search button-main button-white' variant="primary" onClick={() => {
                                                        handleSearch();
                                                        setPage('1');
                                                    }} >Search</Button>

                                                </div>
                                            </InputGroup>
                                        </Col>
                                    </Row>

                                </div>
                                <div className='box-table'>
                                    <Card >
                                        <Card.Body>

                                            <Table style={{ width: '100%', overflowX: 'auto', overflowY: 'auto' }} >
                                                <thead className='thead-text'>
                                                    <tr style={{ textAlign: 'center' }}>
                                                        <th width={'5%'}><p>No</p></th>
                                                        <th width={'20%'} onClick={() => requestSort('name')} ><p className='th-Text'>Book Title <span>{getIcon('name')}</span></p></th>
                                                        <th width={'15%'} onClick={() => requestSort('series')}><p className='th-Text'>Book Series <span>{getIcon('series')}</span></p></th>
                                                        <th width={'15%'} onClick={() => requestSort('author')}><p className='th-Text'>Author Name <span>{getIcon('author')}</span></p></th>
                                                        <th width={'5%'} onClick={() =>  requestSort('category')}><p className='th-Text'>Category <span>{getIcon('category')}</span></p></th>
                                                        <th width={'5%'} onClick={() =>  requestSort('price')}><p className='th-Text'>Price (฿) <span>{getIcon('price')}</span></p></th>
                                                        <th width={'8%'}><p>PDF File </p></th>
                                                        <th width={'10%'} onClick={() => requestSort('publishDate')}><p className='th-Text'>Publish Date <span>{getIcon('publishDate')}</span></p></th>
                                                        <th width={'12%'} onClick={() => requestSort('status')}><p className='th-Text'>Status <span>{getIcon('status')}</span></p></th>
                                                        <th width={'10%'}  ><p>Action</p></th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    {Array.isArray(datagetEbookDetails) ? (
                                                        datagetEbookDetails.map((option, index) => (
                                                            <tr key={option.ebook_id}>
                                                                <td style={{ justifyContent: 'center', verticalAlign: 'middle' }}>  <span className='td-text'>  {((page - 1) * limitPageEbookDetails) + index + 1}  </span> </td>

                                                                <td>
                                                                    <p className='td-text image-as-title'>
                                                                        <ImageWithPlaceholder
                                                                            src={accessPath + option.cover_image_path}
                                                                            placeholder={placeholderImg}
                                                                            className = 'book-overview-page'
                                                                        />
                                                                        
                                                                        <span style={{ paddingLeft: '15px', paddingTop: '0px' }}>
                                                                            {option.book_title}
                                                                        </span>
                                                                    </p>
                                                                </td>
                                                                <td style={{ verticalAlign: 'middle' }}>
                                                                    <span className='td-text'>
                                                                        {option.serie_name}
                                                                    </span>
                                                                </td>

                                                                <td style={{ verticalAlign: 'middle' }}>  <span className='td-text'> {option.book_author} </span> </td>
                                                                <td style={{ verticalAlign: 'middle' }}>  <span className='td-text'> {option.name_en} </span> </td>
                                                                <td style={{ verticalAlign: 'middle' }}>  <span className='td-text'>
                                                                    {
                                                                        option.sale_type === 'for_sale' ? convertToStringWith2Decimal(option.price) : 'Free'
                                                                    } </span> </td>
                                                                <td style={{ verticalAlign: 'middle' }}>

                                                                    
                                                                            {option.uploaded_pdf_path ? (
                                                                               
                                                                               <span className='td-text'>
                                                                                    There is a file uploaded
                                                                                    </span>

                                                                        
                                                                            ) : (
                                                                          
                                                                                <span className='td-text'>
                                                                                    There is no file uploaded
                                                                                    </span>
                                                                         
                                                                            )}
                                                                 
                                                               
                                                                </td>
                                                                <td style={{ verticalAlign: 'middle' }}>  <span className='td-text'>
                                                                    {
                                                                        option.publish_options === 'as_review_pass' ? 'As soon as review pass' : (localeString(option.publish_date) || 'As soon as review pass')

                                                                    } </span> </td>

                                                                <td style={{ verticalAlign: 'middle' }}>  <span className='td-text image-as-title'>
                                                                    <StatusEbooks status={option.approve_status_id} />
                                                                    {option.status_name}
                                                                </span>

                                                                </td>
                                                                <td style={{ verticalAlign: 'middle' }}>

                                                                    <a onClick={() => handleViewEbooks(option.ebook_id, option.book_title)}
                                                                        className='link-button normal-link'>
                                                                        View
                                                                    </a>
                                                                    <br></br>

                                                                    {option.approve_status_id != STATUS_READY_FOR_SALE && option.approve_status_id != STATUS_PENDING_RELEASE && option.approve_status_id != STATUS_IN_REVIEW && (
                                                                        <a onClick={() => handleEditEbooks(option.ebook_id)}
                                                                            className='link-button normal-link'>
                                                                            Edit
                                                                        </a>
                                                                    )}
                                                                    <br></br>


                                                                </td>

                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <Form.Label type="text">
                                                            {datagetEbookDetails && datagetEbookDetails['book_title'] ? datagetEbookDetails['book_title'] : ""}
                                                        </Form.Label>
                                                    )}



                                                </tbody>

                                            </Table>

                                            {(datagetEbookDetails.length < 1 && keyword !== '') ?
                                            <div className='search-no-result'><center><strong>No results for '{keyword}'</strong></center></div>: ''
                                            }

                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <PaginationControl
                                                    page={page}
                                                    between={4}
                                                    total={countEbookDetails}
                                                    limit={limitPageEbookDetails}
                                                    changePage={(page) => {
                                                        setPage(page);
                                                        showEbookDetails(page , sortColumn);
                                                        dispatch(setCurrentManageBookPage(page))
                                                    }}
                                                    ellipsis={10}
                                                />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>

                        </main>
                    </Col>


                </Row>


                <Modal show={importEbookOpen} onHide={handleImportEbookClose} size="lg" backdrop="static" keyboard={false} >
                    <Modal.Header >
                        <Modal.Title>Import TSV E-Book</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

    
                    {messageImportSuccess !== 'Success' && (
                        <>
                            <Form.Control 
                                required 
                                type="file" 
                                accept=".tsv" 
                                onChange={handleFileChange} 
                            />
                            <br />
                        </>
                    )}


                       
                      
                        {showAlertCheckFile && <Alert variant="danger">Please select the .TSV file before proceeding.</Alert>}
                        {showSpinner && (
                            <div>
                             <br></br>  <br></br>
                             <center>
                            <Spinner animation="border" />
                            </center>
                            </div>
                        )}
                        {showDataImport && (
                            <div className="mt-4">
                                <h4>Summary {messageStatus} </h4>
                                <p>Total {messageStatus}: {totalData}</p>
                                <br></br>
                                <p>
                                    Errors: {errorCount}
                                    <br></br>
                                    {messageImportSuccess}: {nonErrorCount}
                                </p>

                                <br></br>
                                {isError ? (
                                    <Alert variant="danger">
                                        <Alert.Heading>Errors Found</Alert.Heading>
                                        <p>There are errors in the {messageStatus} data</p>
                                        <ListGroup as="ol" numbered className="list-group-danger">
                                            {
                                                errorDataEbook.map((option, index) => (
                                                    <ListGroup.Item as="li">Row Number: {option['rowNumber']}, Column Name: {option['errorColumns']}</ListGroup.Item>
                                                ))
                                            }


                                        </ListGroup>

                                    </Alert>
                                ) : (
                                    <Alert variant="success">
                                        <Alert.Heading>{capitalizeFirstLetter(messageStatus)} Data Success</Alert.Heading>
                                        <p>No errors were found in the {messageStatus} data.</p>

                                    </Alert>
                                )}
                            </div>
                        )}


                    </Modal.Body>

                    <Modal.Footer>

                    {checkImport ? (
                    <>
                        <Button
                            className='button-search button-main button-white'
                            style={{ width: '150px' }}
                            variant="primary"
                            disabled={showSpinner}
                            onClick={() => handleImportEbookClose()}
                        >
                            Done
                        </Button>
                    </>
                    ) : (
                        <>
                            <Button
                                className='button-search button-main button-white'
                                style={{ width: '150px' }}
                                variant="primary"
                                disabled={showSpinner}
                                onClick={() => handleImportEbookClose()}
                            >
                                Close
                            </Button>
                            <Button
                                className='button-search button-main button-white'
                                style={{ width: '150px' }}
                                variant="primary"
                                disabled={showSpinner}
                                onClick={() => handleCheckImportTsv()}
                            >
                                Validate TSV
                            </Button>
                            <Button
                                className='button-action button-main button-orange'
                                style={{ width: '150px' }}
                                variant="primary"
                                disabled={showSpinner}
                                onClick={() => handleImportTsv()}
                            >
                                Import TSV
                            </Button>
                        </>
                    )}


                       
                    </Modal.Footer>
                </Modal>


            </div >
            : ''
    );
};



export default ManageBooks;

