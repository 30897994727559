//import logo from './logo.svg';
import React from 'react';
import { useDispatch } from 'react-redux';
import { userSetLevel, userSetName, userSetProfile } from '../../actions';
import { Link } from 'react-router-dom';
import logo from '../../image/logo_main.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import '../../helper/ApiHelper.js'
import { emailLogin, sendVerifyAccountEmailWithoutLogin } from '../../api'
import { useNavigate, useLocation } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { getProfile } from '../../api/index.js'
import { hideLoading, showLoading } from '../../helper/Helper.js';
import { dialogConfirmSetData, dialogConfirmSetShowModal } from '../../actions/confirmDialog.action.js';
import packageJson from '../../../package.json';

function Login() {
  const appVersion = packageJson.version;
  const userLevel = useSelector(state => state.user.userLevel) || "";
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(!regEmail.test(formData.email)){
      // return 'Invalid Email';
      dispatch(dialogConfirmSetData({
            title: "Error",
            message: "Invalid Email.",
            closeButton: "",
            confirmButton: "OK",
            eventConfirmButton: () => null,
            eventCloseButton: () => null,
            showCloseButton: false
        }));
        // ** ทำให้ dialog โชว์ขึ้นมา **
        dispatch(dialogConfirmSetShowModal(true));
    } else {
      showLoading();
      emailLogin(formData.email, formData.password).then(handleEmailLoginResponse)
    }
  }
  const handleEmailLoginResponse = async (response) => {
    hideLoading();
    
    if (response.data['error'] === false) {

      if (response.data['result']['isContainPublisherDetail'] === true) {

        const getProfileResponse = await getProfile();

        if (getProfileResponse.data.error === true) {
          dispatch(userSetProfile(null));
          dispatch(userSetLevel(""));
          //กำหนดวันที่หมดอายุของ Cookie ให้เป็นวันที่ผ่านมาแล้ว (วันที่ 1 มกราคม 1970 เวลา 00:00:01 GMT) ซึ่งจะทำให้ Cookie นั้นหมดอายุและถูกลบทิ้ง.
          document.cookie = 'ebookCMSUserLogin' + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          navigate('/', { replace: true });
        } else {
          dispatch(userSetProfile(getProfileResponse.data.profile));
          dispatch(userSetLevel(response.data['result']['user']['level']));
          dispatch(userSetName(response.data.result.user.name));

          if (response.data['result']['user']['level'] == 'admin') {
            navigate('/BooksApproval', { replace: true });
          } else {
            navigate('/ManageBooks', { replace: true });
          }
        }

      } else {
        if (response.data['result']['user']['level'] == 'admin') {
          // window.location.reload();
        } else {
          navigate('/SignUpInfo-Personal',{
            state: {
              email: formData.email,
              password: null,
            },
            // replace: true 
          });
          // window.location.reload();
        }

      }

      // Get current timestamp
      const timestamp = new Date().getTime();
      const expires = new Date();
      const days = 1;
      expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
      document.cookie = `${'ebookCMSUserLogin'}=${'T='+timestamp};expires=${expires.toUTCString()};path=/`;

    } else {

      let title = response.data["message"]["title"];
      let message = response.data["message"]["message"];
      let button = response.data["message"]["button"];
      
      if(response.data["error_code"] == 122){
        
        let verifyToken = response.data["result"]["verifyEmailToken"];

        let confirmAction = () => {

          showLoading();

            sendVerifyAccountEmailWithoutLogin(verifyToken).then(async responseResendVerifyEmail => {
              hideLoading();

              let verifyTitle = responseResendVerifyEmail.data["message"]["title"];
              let verifyMessage = responseResendVerifyEmail.data["message"]["message"];
              let verifyButton = responseResendVerifyEmail.data["message"]["button"];

              showAlertDialog(verifyTitle, verifyMessage,verifyButton, null);

          })
        };

        let okAction = () => {}

        showConfirmDialog(title, message, button,"OK", confirmAction, okAction);
      }else{
        showAlertDialog(title,message,button);
      }
      
    }
  }

  function showAlertDialog(title, message, button, okAction){

    if(button == null){
      button = "OK";
    }

    dispatch(dialogConfirmSetData({
      title: title,
      message: message,
      closeButton: null,
      confirmButton: button,
      eventConfirmButton:okAction,
      eventCloseButton: null,
      showCloseButton: false
    }));

    // ** ทำให้ dialog โชว์ขึ้นมา **
    dispatch(dialogConfirmSetShowModal(true));
  }

  function showConfirmDialog(title, message, buttonTitle, closeButtonTitle, confirmAction, okAction){
    dispatch(dialogConfirmSetData({
        title: title,
        message: message,
        closeButton: closeButtonTitle,
        confirmButton: buttonTitle,
        eventConfirmButton: confirmAction,
        eventCloseButton: okAction,
        showCloseButton: true
    }));

    // ** ทำให้ dialog โชว์ขึ้นมา **
    dispatch(dialogConfirmSetShowModal(true));
  }

  const location = useLocation();
  useEffect(() => {
    // Replace the current history entry with the login page entry
      window.history.replaceState(null, '', location.pathname);
  }, [location]);

  useEffect(() => {
    setPageLoaded(true)
}, []);

  return (
    (pageLoaded && userLevel === "") ?
    <div className="boyd-div">
      <meta name="viewport" className="" content="width=device-width, initial-scale=1.0" />
      <div className="container-fluid">
        <div className="container" style={{width: '500px', marginTop: '80px'}}>
          <div className="row">
            <div className='col-md-12 col-sm-12 col-xs-12 login-content'>
              <center >
                <img src={logo} className="App-logo" alt="logo" width="300px" style={{marginBottom: '70px'}}/>
                <h3> <b style={{ fontSize: 30, color: '#4A4C51' }}> PUBLISHER CONSOLE</b></h3>
              </center>
              <br></br>
              <form onSubmit={handleSubmit}>
                <div  style={{marginBottom: '20px', textAlign: 'center'}}>
                  <input className='form-control' type="email" name="email" value={formData.email || ''} onChange={handleChange} placeholder="Email" form-control="true" required />
                </div>

                <div style={{marginBottom: '20px', textAlign: 'center'}}>
                  <input type="password" name="password" className='form-control' value={formData.password || ''} onChange={handleChange} placeholder="Password" form-control="true" required />
                </div>

                <div style={{marginBottom: '10px', textAlign: 'center'}}>
                  <button type="submit" className="button-bold-text"  >SIGN IN</button>
                </div>

                {showError && (
                  <Alert variant="danger">
                    <center>
                      Incorrect email or password
                    </center>
                  </Alert>
                )}

                <div className="d-flex justify-content-between">
                  <div>
                    <Link to="/ForgotPassword" className="forgotPassword"><strong>Forgot Password?</strong></Link>
                  </div>

                  <div>
                    <span className="login-option">Don't have an account?</span>
                    <Link to="/SignUp" className="login-option-button">Sign Up!</Link>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div >

      <div style={{marginTop: '80px', marginRight: '50px', textAlign: 'right', color: 'white' }}> 
        <span>{appVersion}</span> 
      </div>

    </div >
  : ''
  );
}

export default Login;

