import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from '../../components/navbar/Head.js';
import NavBar from '../../components/navbar/NavBar.js';
import Card from 'react-bootstrap/Card';
import { useState, useEffect } from 'react';
import moment from 'moment';
import 'react-tagsinput/react-tagsinput.css'
import { getPaymentDetailData } from '../../api'
import { useNavigate, useLocation } from 'react-router-dom';
import { exportReportCSV,limitPageEbookDetails, convertToNumberWith2Decimal,convertToStringWith2Decimal,getCurrencyDisplay } from '../../api/index.js'
import Table from 'react-bootstrap/Table';
import { PaginationControl } from 'react-bootstrap-pagination-control';

const ViewFinancial = () => {
    const [datagetViewPayment, setDataViewPayment] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(1);
    const [grossTotalAmout, setGrossTotalAmout] = useState(0);
    const [amountOfWithholdingTax, setAmountOfWithholdingTax] = useState(0);
    const [netTotalAmount, setnetTotalAmount] = useState(0);
    const location = useLocation();
    const { periodDate, DateView } = location.state || {};;
    
    const periodDateMonth  = periodDate.substring(0, 7)
    const periodDateFirstString = moment(periodDate).startOf('month').format("DD/MM/YYYY");
    const periodDateLastString = moment(periodDate).endOf('month').format("DD/MM/YYYY");

    const navigate = useNavigate();
    const back = (periodDate = '', bookTitle = '') => {
        navigate('/Financial', { replace: true });
    };

    const showViewFinancial = () => {
        getPaymentDetailData(periodDateMonth).then(response => {
            var responseData = response.data['data']
            var paymentValue = response.data['payment_data']
            setCount(responseData.length);
            
            var grossTotal = paymentValue["earned"];
            var withholdingTax = paymentValue["withholding_tax"];
            var netAmount = paymentValue["total_amount"];

            setDataViewPayment(responseData);
            setGrossTotalAmout(grossTotal);
            setAmountOfWithholdingTax(withholdingTax);
            setnetTotalAmount(netAmount);
        });
        
    }

    useEffect(() => {
        showViewFinancial();
    }, []);

    //จัดหน้า limitPageEbookDetails
    const startIndex = (page - 1) * limitPageEbookDetails;
    const endIndex = startIndex + limitPageEbookDetails;
    const booksToShow = datagetViewPayment.slice(startIndex, endIndex);

    return (
        <div >
            <Head />
            <Row className='row-navbar'>
                <NavBar />
                <Col className='row-context' >
                    <main>
                        <div className='box-context'>
                            <span className='title-text'><div className='title-content-text' onClick={() => window.location.replace('/Financial')}>Financial </div><div style={{marginLeft: '10px',marginRight: '10px'}}>{'›'}</div>View Details  </span>
                            <p className='context-text'> All of your financial, bank account and view sales report and payment data.</p>
                            <Card className='card-box'>
                                <Card.Body >

                                    <div className='box-table-Account' style={{paddingTop: '10px'}}>
                                        <Row>
                                            <div className='col-md-12' >
                                                <Row>
                                                    <div className='col-md-6'>
                                                        <p className='context-text'>Detail Payment Month<br></br>
                                                            {DateView}<br></br>
                                                            Sales from {periodDateFirstString} - {periodDateLastString}<br></br>
                                                        </p>
                                                    </div>

                                                    <div className='col-md-6 ' style={{ textAlign: 'right',paddingRight: '80px' }}>
                                                        <p className='context-text-p2'>
                                                            Gross Total Amount <br />
                                                            {getCurrencyDisplay(convertToNumberWith2Decimal(grossTotalAmout))}
                                                            <br />
                                                            Amount of Withholding Tax (3%)<br />
                                                            {getCurrencyDisplay(convertToNumberWith2Decimal(amountOfWithholdingTax))}<br />
                                                            Net Total Amount<br />
                                                            {getCurrencyDisplay(convertToNumberWith2Decimal(netTotalAmount))}<br />
                                                        </p>
                                                    </div>
                                                    <div className='col-md-12 '>
                                                    <a onClick={() => exportReportCSV(datagetViewPayment, periodDateMonth)}
                                                        className='link-button normal-link'
                                                        style={{ float: 'right' ,marginRight: '80px' }}>
                                                        Download CSV
                                                    </a>
                                                    </div>
                                                </Row>
                                            </div>
                                        </Row>

                                        <br></br><br></br>
                                        <Table >
                                            <thead className='thead-text'>
                                                <tr>
                                                    <th><p>No</p></th>
                                                    <th><p>Book</p></th>
                                                    <th><p>Book Type</p></th>
                                                    <th><p>ISBN</p></th>
                                                    <th><p>ISBN E-Book</p></th>
                                                    <th><p>Author Name</p></th>
                                                    <th><p>Quantity</p></th>
                                                    <th><p>Total Income (฿)</p></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(datagetViewPayment) ? (
                                                    booksToShow.map((option, index) => (
                                                        <tr key={index}>
                                                            <td > <p className='td-text'> <br></br>{((page - 1) * limitPageEbookDetails) + index + 1}</p> </td>
                                                            <td > <p className='td-text'> <br></br>{option['book_title']} </p> </td>
                                                            <td > <p className='td-text'> <br></br>
                                                            
                                                            {option['book_type'] ?? '-' }
                                                            
                                                             </p> </td>
                                                            <td > <p className='td-text'> <br></br>{option['isbn_ebook_number'] ?? '-'} </p> </td>
                                                            <td > <p className='td-text'> <br></br>{option['isbn_number'] ?? '-'} </p> </td>
                                                            <td > <p className='td-text'> <br></br>{option['book_author'] ?? '-'} </p> </td>
                                                            <td > <p className='td-text'> <br></br>{option['quantity']} </p> </td>
                                                            <td > <p className='td-text'> <br></br>{convertToStringWith2Decimal(option['total_income'])} </p> </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <Form.Label type="text">

                                                    </Form.Label>
                                                )}
                                            </tbody>
                                        </Table>

                                    </div>


                                </Card.Body>
                            </Card>
                        </div>


                    </main>

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                        <PaginationControl
                            page={page}
                            between={4}
                            total={count}
                            limit={limitPageEbookDetails}
                            changePage={(pageNumber) => {
                                setPage(pageNumber);
                            }}
                            ellipsis={10}
                        />

                    </div>
                </Col>


            </Row>
        </div >
    );
};

export default ViewFinancial;
